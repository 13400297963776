import { IIconFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import Icon, { IconProps } from './Icon'

const contentfulSizeMapper: Record<IIconFields['size'], IconProps['size']> = {
  'Micro (16x16)': 'micro',
  'Small (20x20)': 'small',
  'Default (24x24)': 'normal',
  'Large (32x32)': 'large',
  'Extra Large (48x48)': 'extraLarge',
}

const contentfulAlignmentMapper: Record<IIconFields['alignment'], IconProps['alignment']> = {
  Center: 'center',
  Left: 'flex-start',
  Right: 'flex-end',
}

const ContentfulIcon = withContentful<IIconFields, IconProps>(Icon, {
  size: (props) => contentfulSizeMapper[props.fields.size],
  alignment: (props) => contentfulAlignmentMapper[props.fields.alignment],
})

export default ContentfulIcon
