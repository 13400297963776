import styled from 'styled-components'

export type AlignmentProps = {
  alignment: React.CSSProperties['justifyContent']
}

export const Alignment = styled.div<AlignmentProps>`
  display: flex;
  justify-content: ${(props) => `${props.alignment}`};
`
