import { Icon as AlliumIcon } from '@telus-uds/components-web'
import { mapDataAttrsToDataSet } from '../../../utils/dataAttributes'
import { Alignment, AlignmentProps } from './Styles'
import { getIcon } from '../../../utils/getIcon'
import { IIconFields } from '@/contentful-types'

export type IconProps = {
  iconName: string
  label?: string
  size?: 'micro' | 'small' | 'normal' | 'large' | 'extraLarge'
  scalesWithText?: boolean
  alignment?: AlignmentProps['alignment']
  color?: Lowercase<IIconFields['color']>
}

const Icon = ({
  iconName,
  label,
  size,
  color,
  scalesWithText = false,
  alignment = 'flex-start',
  ...rest
}: IconProps) => {
  const IconComponent = getIcon(iconName)

  if (!IconComponent) return null

  return (
    <Alignment alignment={alignment}>
      <AlliumIcon
        accessibilityLabel={label}
        variant={{ size, color }}
        icon={IconComponent}
        scalesWithText={scalesWithText}
        dataSet={mapDataAttrsToDataSet(rest)}
      />
    </Alignment>
  )
}

export default Icon
